import React, { useState, useMemo } from "react";
import * as PR from "../../prime-modules/index";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { resetPassword } from "../../services/api";
import { useDispatch, useSelector } from "react-redux";
import { affiliateAuthActions } from "../../store/affiliateAuth";
import * as Yup from "yup";
import { useFormik } from "formik";
import { removeWhiteSpacesInPassword } from "../../utils/index";
import { eSIMLogo } from "../../assets/images/index";

const ResetPassword = () => {
  const dispatch = useDispatch();
  const toast = React.useRef(null);
  const location = useLocation();
  const navigate = useNavigate();
  const [disableBtn, setDisableBtn] = useState(false);

  const queryParams = new URLSearchParams(location.search);
  const resetUUID = queryParams.get("id");

  const affiliateData = useSelector(
    (state) => state.affiliateAuth.affiliateSessionData
  );
  const headers = useMemo(() => {
    return { sessionid: affiliateData.sessionId };
  }, [affiliateData.sessionId]);

  const formInitialValues = {
    password: "",
    confirmPassword: "",
  };
  const validationSchema = () => {
    const passwordError =
      "Password should be between 8 and 20 characters with no space, atleast one character and one number";
    return Yup.object().shape({
      password: Yup.string()
        .required("Password is required")
        .min(8, passwordError)
        .max(20, passwordError)
        .matches(/\d/, passwordError)
        .matches(/[A-Za-z]/, passwordError)
        .strict(true),
      confirmPassword: Yup.string()
        .required("Confirm Password is required")
        .when("password", {
          is: (val) => (val && val.length > 0 ? true : false),
          then: Yup.string().oneOf(
            [Yup.ref("password")],
            "Passwords are not matching"
          ),
        }),
    });
  };
  const submitHandler = (formData) => {
    if (!resetUUID) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Invalid or missing reset token.",
      });
      setDisableBtn(false);
      return;
    }
    setDisableBtn(true);
    const getResponse = (response) => {
      if (response.result === "SUCCESS") {
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "Your password has been successfully reset.",
        }); // resetPwd.successMessage
        formik.resetForm();
        dispatch(affiliateAuthActions.onLogout());
        setTimeout(() => {
          setDisableBtn(false);
          navigate("/login"); // Redirect to login page
        }, 3000);
      } else {
        const error = response.error;
        toast.current.show({
          severity: error.severity,
          summary: "Error",
          detail: error.errorMsg ? error.errorMsg : error.summary,
        });
        setDisableBtn(false);
      }
    };
    const resetPasswordRequest = {
      passwordResetUUID: resetUUID,
      password: formData.password,
    };
    resetPassword(resetPasswordRequest, headers, dispatch, getResponse);
  };
  const formik = useFormik({
    initialValues: formInitialValues,
    validationSchema: validationSchema,
    onSubmit: submitHandler,
  });
  return (
    <>
      <section className="admin-login-section">
        <div className="grid grid-nogutter">
          <div className="col-12">
            <div className="login-wrapper">
              <div className="login-header flex align-items-center justify-content-between">
                <PR.Image
                  src={eSIMLogo}
                  alt="eSIMCrew Logo"
                  className="logo-img"
                />
              </div>
              <form
                autoComplete="off"
                onSubmit={formik.handleSubmit}
                className="affiliate-forms"
              >
                <div className="heading-forms">
                  <h3>New Password</h3>
                  <p>
                    Please create a new password that you don't use on any other
                    site.
                  </p>
                </div>
                <div className="grid">
                  {/* Password */}
                  <div className="col-12 md:col-12">
                    <span className="p-input-icon-left password-left w-12">
                      <PR.Password
                        feedback={false}
                        id="password"
                        name="password"
                        placeholder="Create new password"
                        className="w-12"
                        toggleMask
                        value={formik.values.password}
                        onChange={(e) =>
                          removeWhiteSpacesInPassword(e, formik, "password")
                        }
                        onBlur={formik.handleBlur}
                      />
                    </span>
                    {formik.errors.password && formik.touched.password && (
                      <div className="error-message">
                        {formik.errors.password}
                      </div>
                    )}
                  </div>

                  {/* Confirm Password */}
                  <div className="col-12 md:col-12">
                    <span className="p-input-icon-left password-left w-12">
                      <PR.Password
                        feedback={false}
                        id="confirmPassword"
                        name="confirmPassword"
                        placeholder="Confirm new password"
                        className="w-12"
                        toggleMask
                        value={formik.values.confirmPassword}
                        onChange={(e) =>
                          removeWhiteSpacesInPassword(
                            e,
                            formik,
                            "confirmPassword"
                          )
                        }
                        onBlur={formik.handleBlur}
                      />
                    </span>
                    {formik.errors.confirmPassword &&
                      formik.touched.confirmPassword && (
                        <div className="error-message">
                          {formik.errors.confirmPassword}
                        </div>
                      )}
                  </div>

                  <PR.Button
                    label="Reset Password"
                    type="submit"
                    disabled={disableBtn}
                    className="login-btn w-12"
                  />
                  <div className="col-12 text-center">
                    <p className="login-p">
                      New here? <Link to="/signup"> Sign Up</Link>
                    </p>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
      <PR.Toast ref={toast} position="top-right" />
    </>
  );
};

export default ResetPassword;
