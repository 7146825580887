import React, { useState, useMemo } from "react";
import * as PR from "../../prime-modules/index";
import { Link, useNavigate } from "react-router-dom";
import { forgotPassword } from "../../services/api";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { useFormik } from "formik";
import { eSIMLogo } from "../../assets/images/index";

const ForgotPassword = () => {
  const dispatch = useDispatch();
  const toast = React.useRef(null);
  const navigate = useNavigate();
  const [disableBtn, setDisableBtn] = useState(false);
  const affiliateData = useSelector(
    (state) => state.affiliateAuth.affiliateSessionData
  );
  const headers = useMemo(() => {
    return { sessionid: affiliateData.sessionId };
  }, [affiliateData.sessionId]);

  const validationSchema = () => {
    return Yup.object().shape({
      email: Yup.string()
        .trim()
        .email("Invalid email")
        .required("Email is required"),
    });
  };
  const submitHandler = (formData) => {
    setDisableBtn(true);
    const getResponse = (response) => {
      if (response.result === "SUCCESS") {
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "Password reset link has been sent to your registered email.",
        });
        formik.resetForm();
        setTimeout(() => {
          navigate("/login");
        }, 2000);
      } else {
        const error = response.error;
        toast.current.show({
          severity: error.severity,
          summary: "Error",
          detail: error.errorMsg ? error.errorMsg : error.summary,
        });
      }
      setDisableBtn(false);
    };
    const emailVal = formData.email.trim();
    forgotPassword(
      `forgotpassword?email=${emailVal}`,
      headers,
      dispatch,
      getResponse
    );
  };
  const formik = useFormik({
    initialValues: { email: "" },
    validationSchema: validationSchema,
    onSubmit: submitHandler,
  });
  return (
    <>
      <section className="admin-login-section">
        <div className="grid grid-nogutter">
          <div className="col-12">
            <div className="login-wrapper">
              <div className="login-header flex align-items-center justify-content-between">
                <PR.Image
                  src={eSIMLogo}
                  alt="eSIMCrew Logo"
                  className="logo-img"
                />
              </div>
              <form
                autoComplete="off"
                onSubmit={formik.handleSubmit}
                className="affiliate-forms"
              >
                <div className="heading-forms">
                  <h3>Forgot password?</h3>
                  <p>
                    Please enter your email address below and we will send you a
                    ‘Password Reset’ link
                  </p>
                </div>
                <div className="grid">
                  <div className="col-12 md:col-12">
                    <span className="p-input-icon-left w-12">
                      <i className="pi pi-envelope" />
                      <PR.InputText
                        id="email"
                        name="email"
                        placeholder="Email address"
                        className="w-12"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        autoComplete="off"
                      />
                    </span>
                    {formik.errors.email && formik.touched.email ? (
                      <div className="error-message">{formik.errors.email}</div>
                    ) : (
                      ""
                    )}
                  </div>

                  <div className="col-12 mt-3 navigate">
                    <PR.Button
                      label="Send Recovery Email"
                      type="submit"
                      disabled={disableBtn}
                      className="login-btn w-12"
                    />
                  </div>
                  <div className="col-12 text-center">
                    <p className="login-p">
                      New here? <Link to="/signup">Sign Up</Link>
                    </p>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
      <PR.Toast ref={toast} position="top-right" />
    </>
  );
};

export default ForgotPassword;
