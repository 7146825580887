import React from 'react';
// **** Main CSS **** //
import './assets/scss/main.scss';

import { useSelector } from "react-redux";

import Login from './affiliate/login/Login';
import Sales from './affiliate/sales/Sales';
import { Navigate } from 'react-router-dom';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Signup from './affiliate/signup/Signup';
import VerifyEmail from './affiliate/signup/VerifyEmail';
import ForgotPassword from './affiliate/forgotpassword/forgotpassword';
import ResetPassword from './affiliate/resetpassword/resetpassword';


function App() {
  const isAuthAffiliate = useSelector(state => state.affiliateAuth.affiliateLoggedIn);

  return (
    <Router>
      <Routes>
        <Route path='/' element={ <Navigate to='/login'/>} />
        <Route path='/signup' element={<Signup />} />
        <Route path='/verify-email' element={<VerifyEmail />} />
        <Route path='/login' element={!isAuthAffiliate ? <Login /> :  <Navigate to='/sales' />} />
        <Route path='/sales' element={isAuthAffiliate ? <Sales /> : <Navigate to='/login' />} />
        <Route path='/forgot-password' element={ <ForgotPassword />} />   
        <Route path='/reset-password' element={ <ResetPassword />} />   
        <Route path='*' element={isAuthAffiliate ? <Navigate to='/not-found' /> : <Navigate to='/' />} />
      </Routes>
    </Router>
  );
}
export default App;