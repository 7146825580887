import React, { useState } from 'react';
import "../login/Login.scss";
// import { envConfig } from "../../utils/settings";

const AffiliateFooter = () => {
  // const mainBaseUrl = envConfig.REACT_APP_MAIN_URL;
  const [year] = useState(new Date().getFullYear())
  return (
    <>
        <footer className='affiliate-footer'>
            <p>&copy; {year} <a href="https://bluehouse-technology.co.uk" target="_blank" rel="noreferrer">Bluehouse Technology</a>.All Rights Reserved.</p>
        </footer>
    </>
  )
}
export default AffiliateFooter;